$avatar-width: 160px;
$avatar-border-width: 8px;

@media (min-width: 48em) {
	html .post {
		font-size: 18px;
	}
	.post .content {
		margin-left: auto;
		margin-right: auto;
	}
}
@media (min-width: 64em) {
	.post .content {
		margin-left: auto;
		margin-right: auto;
	}
}
.post .content {
	margin-left: auto;
	margin-right: auto;
}

.back-to-neppari {
	@extend .header;
	margin-top: 0;
	padding: 0.2em 1em;

	&, a:link, a:visited {
		color: $accent-color;
	}
	a:hover, a:active {
		color: $faded-accent-color;
	}
}

.blog-header {
	background-color: $accent-color;

	text-align: center;
	padding: 2em 1em 1.5em 1em;

	&, a:link, a:visited, a:hover, a:active {
		color: $text-color-on-accent;
	}

	h1 {
		color: $text-color-on-accent;
		font-size: 3.25em;
		line-height: 1em;
		margin-top: 0;
		margin-bottom: 0;
		text-transform: lowercase;
	}

	.tagline {
		@extend .header;
		@extend .h3;
	}
}

.post .content {
	padding-top: 4rem;
	padding-bottom: 0;
}

.blog-frontpage-image {
	float: right;
	width: 30%;
	margin: 0 0 0 20%
}

.welcome-message:after {
    // Clearfix: http://stackoverflow.com/a/1633170
    content:"";
    display:table;
    clear:both;
}

p.first {
	color: #303030;
	font-weight: bold;
}

.welcome-message {
	&, & h3 {
		margin-top: 0;
	}
	//margin-bottom: 3rem; // Disabled because of the image
}

.writer-information {

	$image-container-width: $avatar-width + (2 * $avatar-border-width);
	.image-container {
		float: left;
		border: $avatar-border-width solid $accent-color;
		width: $image-container-width;
		height: $image-container-width;
		border-radius: $image-container-width / 2;
	}

	.image {
		width: $avatar-width;
		height: $avatar-width;
		border: $avatar-border-width solid white;
		//border-radius: $avatar-width / 2;
		//padding: 10px solid white;
	}

	.description {
		margin-left: $image-container-width + (3 * $avatar-border-width);
		padding-top: 2 * $avatar-border-width;
		min-height: $image-container-width;

		.name { @extend .h3; margin-top: 0; }
		.title { font-size: 0.9em; margin-bottom: 0.5em; }
		.bio { font-size: 0.8em; font-style: italic; }
	}
}

.related-posts-header {
	margin-top: 4rem;
	overflow: hidden;
}

.related-posts {
	list-style: circle;
	padding-left: 1rem;

	li a:hover {
		text-decoration: underline;
	}
}

.previous-posts {
	margin-bottom: 3rem;
}