@mixin vendor-prefix($name, $value) {
  @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
    #{$vendor}#{$name}: #{$value};
  }
}

.polaroid {

  // Get rid of jagged edges
  -webkit-backface-visibility: hidden;

  padding: 0.3em 0.3em 1em 0.3em;
  border: 1px solid #f0f0f0;

  @include vendor-prefix('box-shadow', '0.25em 0.25em 0.5em #d0d0d0');
  @include vendor-prefix('border-radius', '3px');

  max-width: 50%;
  margin: 0 auto 1em auto;

  z-index: 1;
}

.polaroid-0 { @include vendor-prefix('transform', 'rotate(-3deg)'); }
.polaroid-1 { @include vendor-prefix('transform', 'rotate(-2deg)'); }
.polaroid-2 { @include vendor-prefix('transform', 'rotate(-1deg)'); }
.polaroid-3 { @include vendor-prefix('transform', 'rotate(1deg)'); }
.polaroid-4 { @include vendor-prefix('transform', 'rotate(2deg)'); }
.polaroid-5 { @include vendor-prefix('transform', 'rotate(3deg)'); }

$polaroid-width-normal: 40%;
$polaroid-width-wide: 50%;
$polaroid-additional-margin: 1em;
@media (min-width: 28em) and (max-width: 48em), (min-width: 62em) {
  .polaroid {
  	float: right;
    max-width: $polaroid-width-normal;
    margin: 0 0 1em $polaroid-additional-margin;
  }

  .extra-wide {
    max-width: $polaroid-width-wide;
  }

  /*
    The following classes are header classes, for
    preventing weird wordraps
  */

  .accommondate-polaroid {
    display: block;
    width: calc(100% - #{$polaroid-width-normal} - #{$polaroid-additional-margin});
    word-wrap: normal;
  }

  .accommondate-wide-polaroid {
    display: block;
    width: calc(100% - #{$polaroid-width-wide} - #{$polaroid-additional-margin});
    word-wrap: normal;
  }
}
