$accent-color: #F2CB52;
$faded-accent-color: darken($accent-color, 28%);
$text-color-on-accent: white;

.sidebar {
	background-color: $accent-color;
	color: $text-color-on-accent;
	font-size: 16px;
	.new {
		font-weight: bold;
	}
}
.content a,
.related-posts li a:hover {
  color: darken($accent-color, 18%);
}

.fainted {
	a { color: $accent-color }
	a:hover { color: darken($accent-color, 18%) }
}

.sidebar-about {
	.pre-title {
		//@extend .small;
		margin: 0;
	}
	h1 {
		margin: -0.35em 0 0.5rem 0.5rem;
	}
	.tagline {
		@extend .small;
		margin: 0 0 1rem 0;
	}
}

.sidebar .faded {
	&, a:link, a:visited {
		color: $faded-accent-color;
	}
	a:hover, a:active {
		color: $text-color-on-accent;
	}
}

.sidebar-nav {
	font-size: 18px;
}

.android, .ios {
	.non-clickable-phone-number {
		display: none;
	}
	.clickable-phone-number {
		display: inline !important;
	}
}

.nepsy-ry {
	margin-top: 2rem;
	display: table;
	border-spacing: 1rem;
    border-collapse: separate;
	.image-container {
		display: table-cell;
		vertical-align: middle;
		width: 140px;
	}
	.description {
		margin-left: 180px;
		display: table-cell;
		vertical-align: middle;
		font-style: italic;
	}
	.image {
		width: 140px;
		margin: 0;
	}
}