h1, h2, h3, h4, h5, h6, .header {
	font-family: "Miso Regular", "Helvetica Neue", "Arial Light", "Arial", sans-serif;
	line-height: 1.1em;
}

h1, h2, h3, h4, h5, h6 {
	margin-bottom: 1rem;
}

.small {
	font-size: 0.8em;
}