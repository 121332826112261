$share-img-height: 32px;

.share-buttons {
	display: block;
	margin: 2rem 0 4rem 0;
}

.share-button {
	display: inline-block;
	margin: 0;
	margin-left: $share-img-height / 10;
	margin-bottom: calc((#{$share-img-height} - 1em) / -2)
}