/*
  $desktop-threshold in styles.scss is also related to these!
*/

@media #{$desktop-threshold} {
  .sidebar {
    padding-top: 1rem;
  }
  .sidebar-sticky {
    bottom: -0.5rem;
  }
  .sidebar-nav-item {
    line-height: 1.3;
  }
}

@media #{$desktop-threshold} and (min-height: 560px) {
  .sidebar-nav-item {
    line-height: 1.52;
  }
}

@media #{$desktop-threshold} and (min-height: 600px) {
  .sidebar-nav-item {
    line-height: 1.75;
  }
}

@media #{$desktop-threshold} and (min-height: 670px) {
  .sidebar {
    padding-top: 2rem;
  }
  .sidebar-sticky {
    bottom: 1rem;
  }
}